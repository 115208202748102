import styled from "styled-components";
import { MainLayout } from "./styles";
import bannerBackgroundIcon from "../../assets/banner_icon_background.png";

const Container = styled.div`
  padding-top: 163px;
  padding-bottom: 235px;
  text-align: center;

  @media (max-width: 1280px) {
    padding-top: 30px;
    padding-bottom: 50px;
  }
`;

const BannerLayout = styled(MainLayout)`
  background: var(--main-color);
  box-shadow: 10px 10px 32px 6px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  padding: 68px 105px 114px 105px;
  box-sizing: border-box;
  position: relative;
  width: 95%;
  max-width: 1280px;

  img {
    position: absolute;
    bottom: -168px;
    right: -125px;
    width: 1200px;
  }

  @media (max-width: 1280px) {
    width: calc(100% - 40px);
    padding: 12px 24px 41px 24px;
    max-width: 400px;

    img {
      width: 300px;
      bottom: -52px;
      right: 0px;
    }
  }
`;

const Description = styled.div`
  font-family: "Noto Sans";
  font-weight: 500;
  font-size: 36px;
  line-height: 49px;
  color: #ffffff;

  @media (max-width: 1280px) {
    font-size: 10px;
    line-height: 14px;
  }
`;
const BannerTitle = styled.div`
  margin-top: 16px;
  font-weight: 500;
  font-size: 54px;
  line-height: 74px;
  color: #ffffff;

  b {
    font-weight: 700;
  }

  @media (max-width: 1280px) {
    font-size: 14px;
    line-height: 19px;
    margin-top: 6px;
  }
`;
const HashTagWrap = styled.div`
  margin-top: 77px;
  display: flex;
  flex-direction: column;
  gap: 20px 0;
  > div {
    gap: 0 20px;
    display: flex;

    > div {
      padding: 20px 32px;
      background: #ffffff;
      border-radius: 20px;
      font-weight: 600;
      font-size: 24px;
      line-height: 33px;
      color: var(--main-color);
    }
  }
  @media (max-width: 1280px) {
    margin-top: 18px;
    gap: 10px 0;

    > div {
      gap: 0 10px;
      > div {
        padding: 5px 8px;
        font-size: 10px;
        line-height: 14px;
      }
    }
  }
`;

const BannerSection = () => {
  return (
    <Container>
      <BannerLayout>
        <Description>선택이 아닌 필수</Description>
        <BannerTitle>
          빠르고 정확한 <b>SNS 마케팅</b>
        </BannerTitle>
        <HashTagWrap>
          <div>
            <div>#최적화</div>
            <div>#인기 게시물</div>
          </div>
          <div>
            <div>#클릭 한번으로 쉽게</div>
            <div>#게시물 컨설팅</div>
          </div>
          <div>
            <div>#쉬운 마케팅</div>
            <div>#계정관리 대행</div>
          </div>
        </HashTagWrap>
        <img src={bannerBackgroundIcon} />
      </BannerLayout>
    </Container>
  );
};

export default BannerSection;
